.list-search {
  height: 36px !important;
  max-width: 300px !important;
  /* margin-top: 15px !important;  */
  padding-bottom: 8px !important;
  padding-left: 25px !important;
  padding-right: 12px !important;
  background-color: #ffffff !important;
  border-radius: 4px 4px 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38) !important;
}

.list-search::-webkit-input-placeholder,
-moz-placeholder {
  padding-left: 2px;
}

.list-search:focus {
  border-bottom: 2px solid;
  background-color: #ffffff;
}

.list-search-container {
  padding: 0 !important;
  position: relative;
  max-width: 300px;
  float: left;
  margin-top: 15px;
}

.search-icon {
  width: 16px;
  height: 16px;
  opacity: 0.54;
}

.list-search-container > .clear-entry {
  position: absolute;
  top: 10px;
  right: 5px;
  outline: none;
}

.list-search-container > img.search-icon {
  position: absolute;
  top: 10px;
  left: 5px;
}
