/*
 * Base structure
 */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  font-size: 14px;
  width: 100%;
}

h1 {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  font-size: 38px;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

img {
  border: 0;
}

a,
a:active,
a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

/*
* Global add-ons
*/

.error {
  color: #c70039;
}

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.main-container {
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  overflow-x: hidden;
  overflow-y: scroll;
}

.container {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
  --webkit-transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
}

.container-open-sidebar {
  padding-left: 256px;
  transition: all 113ms Cubic-bezier(0, 0, 0.2, 1) 0s;
  --webkit-transition: all 113ms Cubic-bezier(0, 0, 0.2, 1) 0s;
}

.container-fluid {
  width: 80%;
  display: block;
  margin: 64px auto 0px auto;
  padding: 0px;
}

.navbar {
  width: 100%;
  height: 64px;
  z-index: 900;
  border: none;
  border-radius: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0px;
  background-color: #2f2d2e;
  transition: all 113ms Cubic-bezier(0, 0, 0.2, 1) 0s;
  --webkit-transition: all 113ms Cubic-bezier(0, 0, 0.2, 1) 0s;
}

.navbar-header {
  height: 64px;
  width: 100%;
  margin: 0 0 32px 0;
}

.navbar-brand {
  height: 64px;
  margin: 0 12px;
  padding: 0;
  display: flex;
  align-items: center;
}

.logomain {
  height: 50px;
  width: auto;
}

.icon {
  width: 50px;
  height: 50px;
  padding: 13px;
  opacity: 1;
}

.icon-menu {
  opacity: 0.5;
  padding: 13px;
  width: 50px;
  height: 50px;
}

.icon-bar {
  margin-bottom: 5px;
}

.navbar-back-button {
  width: 24px;
  height: 24px;
  margin: 13px;
}

#navbar {
  display: block;
  background-color: #2f2d2e;
}

.navbar-toggle {
  border: 0px;
  display: block;
}

.navbar-toggle:hover,
.navbar-toggle:focus {
  background-color: transparent !important;
}

.navbar-right {
  float: right;
  margin-right: -15px;
  display: block;
}

.navbar-left {
  float: left;
  margin-left: 10px;
  display: block;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 0px;
}

.navbar-form {
  display: flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 7px 15px;
}

.navbar-container {
  padding-top: 7px;
}

/*
* Sidebar
*/

.sidebar {
  width: 256px;
  min-height: 100vh;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -256px;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #ffffff;
  padding: 0px;
  transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
  --webkit-transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
}

.sidebar-open {
  left: 0px;
}

.sidebar-link {
  align-items: center;
  height: 64px;
  font-weight: 700;
}

.sidebar-button-link {
  display: flex;
  align-items: center;
  height: 64px;
  font-weight: 700;
  width: 100%;
  border: none;
  outline: none;
}

.sidebar-button-link:hover {
  background-color: #f4f4f4;
}

.sidebar-text {
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  text-decoration: none;
}

/* Sidebar navigation */
.sidebar-list {
  display: block;
  width: 256px;
  margin-bottom: 20px;
  list-style: none;
  flex-wrap: wrap;
  padding-left: 0;
}

.sidebar-list > li > a {
  display: flex;
  text-decoration: none;
}

.sidebar-list > li > a {
  padding: 7px;
}

.sidebar-list > li > a:hover {
  background-color: #f4f4f4;
}

li.active .sidebar-text {
  color: #3385c2;
}

/*
* Main content
*/

.main {
  width: 100%;
  display: block;
  margin: 66px auto 128px;
  padding: 0px;
}

.page-header {
  margin: 0;
  padding: 0;
  border: none;
}

.header-sub-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.list-sub-heading {
  color: #14b1e7;
  font-size: 12px;
  line-height: 18px;
  display: block;
  height: 64px;
  padding-top: 24px;
  padding-bottom: 24px;
}

/*
 * body elements
 */

.body-content-btn {
  margin-top: 8px;
}

.body-content-partial {
  padding-left: 8px;
}

.body-content-partial {
  margin-top: 32px;
  padding-left: 0px;
}

/*
 * buttons
 */
.btn {
  font-weight: 700;
  height: 36px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #14b1e7;
  border-color: #14b1e7;
  height: 36px;
  padding: 0px 12px;
  min-width: 90px;
}

.btn-default {
  height: 36px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border: 0;
  margin: 0 14px;
}

.btn-default-alt {
  height: 36px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  border: 0;
  margin: 0;
}

.btn-delete {
  background-color: #e65050;
}

.hidden-button {
  padding: 0 4px;
  background-color: transparent;
  border: none;
  color: #14b1e7;
  outline: none;
}

.formAddButtonContainer {
  width: 61%;
  max-width: 400px;
  float: right;
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin: 32px 5px 11px 0px;
}

.buttonsLeft {
  float: left;
}

.formEditButtonContainer {
  width: 100%;
  max-width: 400px;
  float: left;
  margin: 40px 0px 0px 0px;
  position: relative;
}

.formEditTextAnchorLink {
  width: 100%;
  float: left;
  margin: 18px 0px 0px 0px;
  position: relative;
  color: #14b1e7;
}

/*
 * Modal
 */

.custom-modal {
  margin-top: 100px;
  padding: 16px !important;
  border-radius: 4px !important;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.5) !important;
  width: 75vw !important;
  min-width: 300px !important;
  max-width: 720px !important;
}

.custom-modal-hidden {
  display: none;
}

.modal-form {
  margin-top: 16px;
}

.modal-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 16px;
}

.custom-modal-header-text {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

.custom-modal-dialog {
  margin: 0px;
}

.custom-modal-text {
  max-width: 500px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.custom-modal-footer {
  border: none;
}

.cancelButton {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  margin-right: 14px;
}

/*
* Placeholder dashboard ideas
*/

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}
.placeholder img {
  display: inline-block;
  border-radius: 50%;
}

/*
 * Other
 */
.status-indicator {
  width: 14px;
  height: 14px;
  margin-top: 2px;
  margin-right: 7px;
  float: left;
  border-radius: 7px;
  background-color: #65a943;
}

.status-div {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.eventListItem {
  height: 96px;
}

.list-item-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 96px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.list-item-container:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.list-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  padding-left: 9px;
  width: 100%;
  outline: none;
}

.list-title {
  overflow: hidden;
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.tab {
  width: 33.3%;
  height: 64px;
  max-width: 375px;
  min-width: 100px;
  padding-top: 24px;
  color: hsla(0, 0%, 100%, 0.7);
  line-height: 16px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.tab:hover {
  cursor: pointer;
  color: #fff;
}

.tab:focus {
  outline: 0;
}

.tabs-spacer {
  margin-top: 128px;
}

.react-tabs__tab--selected {
  color: #fff;
  border-bottom: 5px solid #14b1e7;
}

.admin-tab-subheader {
  margin: 0 auto;
  padding: 0px;
  background-color: #2f2d2e;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 800;
  border: none;
  transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
  --webkit-transition: all 98ms Cubic-bezier(0.4, 0, 0.6, 1);
}

.sidebar-spacer {
  left: 256px;
}

.tab-inner-container {
  display: flex;
  overflow: hidden;
  height: 64px;
  max-width: 750px;
  min-width: 300px;
  margin: 0 auto;
  padding: 0px 7px;
}

.list-avatar-div {
  width: 66px;
  height: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
  float: left;
}

.list-avatar-image {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 25px;
}

.form-control,
.form-control-textarea {
  width: 100%;
  padding: 8px 12px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  font-size: 14px;
  line-height: 1.2;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 6px;
}

.form-control {
  height: 50px;
  max-width: 400px;
}

.form-control-textarea {
  min-width: 400px;
  min-height: 108px;
  max-height: 300px;
  resize: vertical;
}

.form-control:focus,
.form-control-textarea:focus {
  border: 2px solid #14b1e7;
  padding: 7px 11px;
  box-shadow: none;
  -webkit-box-shadow: 0px;
  outline: none;
}

.form-control::placeholder,
.form-control-textarea::placeholder {
  color: rgb(0, 0, 0, 0.38);
}

.form-group {
  margin-bottom: 32px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 6px;
  background-image: linear-gradient(45deg, transparent 50%, rgb(0, 0, 0) 50%),
    linear-gradient(135deg, rgb(0, 0, 0) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 8px),
    calc(100% - 15px) calc(1em + 8px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
}

.textAreaContainer {
  max-width: 400px;
}

.form-control:disabled,
.form-control-textarea:disabled {
  background-color: transparent;
  border: dashed 1px rgb(0, 0, 0, 0.16);
  color: rgb(0, 0, 0, 0.38);
}

.textAreaCharCount {
  max-width: 400px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
  text-align: right;
}

.field-label {
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.required-label {
  width: 20%;
  max-width: 400px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  float: left;
  clear: both;
  margin-top: 23px;
}

.required-label-jpom {
  width: 20%;
  max-width: 400px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  float: left;
  margin-top: 42px;
}

.user-details-page {
  height: 100%;
}

.organization-keys-page {
  height: 100%;
}

.avatar-upload {
  width: 150px;
  height: 150px;
  margin: 8px 0px 8px 0px;
  border-radius: 12px;
  background-color: #fff;
}

.avatar-button-container {
  height: 52px;
  display: flex;
  align-items: center;
}

.user-detail-form {
  padding-top: 48px;
  padding-left: 90px;
}

.empty-state {
  width: 200px;
  align-self: center;
  margin: auto;
  text-align: center;
}

.empty-state-img {
  width: 150px;
  display: inline-block;
  margin: auto;
}

.empty-state-text {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.custom-text-danger {
  color: #e65050;
  margin-top: 4px;
}

.custom-danger-border {
  border: 2px solid #e65050;
  padding: 7px 11px;
}

.btn-add-event,
.btn-add-organization {
  float: left;
  margin-top: 15px;
  margin-right: 20px;
}

.search-list-sub-heading {
  padding-top: 64px;
}

/*
* List item icon styling
*/

.list-image-section {
  height: 96px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.list-icon {
  display: block;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  color: black;
  margin: 0px;
}

.list-image-section-button {
  border: none;
  background: transparent;
  outline: none;
  padding: 13px;
}

/*
* Overriding default datepicker styling
*/

.react-datepicker {
  font-size: 1em !important;
}
.react-datepicker__header {
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month {
  font-size: 1em !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
  left: 220px !important;
}

.react-datepicker__time-container {
  width: 8em !important;
}
.react-datepicker-time__header {
  font-size: 1em !important;
}
.react-datepicker-time__header--time {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.react-datepicker__time-box {
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
}
.react-datepicker__time-list {
  padding: 0 !important;
}

.align-right {
  margin-left: auto;
  float: right;
  padding: 0px;
}
