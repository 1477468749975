.mapping-details-container {
  margin: 6px auto 108px;
  padding: 0px;
}

.formdiv.multipleforms {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.formMappingEditButtonContainer {
  margin: 20px 0px 0px 0px !important;
}

.batchcarddiv {
  width: 100%;
  margin-bottom: 16px;
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

.batchcarddiv.hide {
  display: none;
}

.batchcarddiv.hidden {
  display: none;
}

.h1 {
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.h1.headerdiv {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 2px;
  float: none;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.h1.headerdiv.withnosubtext {
  margin-bottom: 9px;
}

.batchinfotext {
  float: left;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.batchinfoitem {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.helpertext {
  max-width: 400px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.helpertext.helpertextgoodstate {
  font-weight: 700;
}

.helpertext.fordragdrop {
  max-width: 500px;
  margin-top: 15px;
}

.helpertext.fordragdrop.forconditions {
  margin-bottom: 16px;
}

.helpertext.forfieldaddition {
  max-width: 500px;
  margin-bottom: 15px;
  color: red;
}

.mappingiconsdiv.fieldIcons {
  margin-top: -13px;
}

.mappingiconsdiv.conditionIcons {
  margin-top: -13px;
}
