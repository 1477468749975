.mappings-filter-row {
  margin-top: 10px;
  margin-bottom: 30px;
}

.mappings-filter-label {
  margin-top: 9px;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mappings-filter-label.api-filter {
  margin-left: 20px;
  margin-right: 30px;
}

.mappings-filter-label.status-filter {
  margin-right: 30px;
}

.mappings-filter-select-row {
  left: -25px;
}

.mappings-filter-select {
  height: 36px;
  padding-left: 10px;
  float: left;
  border-radius: 4px;
  border-width: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  font-weight: 700;
}

.mappings-filter-select-arrow {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-image: url("../../assets/images/chevron-down-solid.svg");
  background-size: 0.8em;
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 12px;
}

.mappings-filter-option {
  background-color: #ffffff;
}

.mapping-filter {
  width: 100%;
  margin-top: 16px;
  float: left;
}

.filterdropdown {
  margin-right: 16px;
  float: left;
}

.filterdropdown {
  margin-right: 16px;
  float: left;
}

.dropdownlabel {
  margin-top: 9px;
  margin-right: 8px;
  float: left;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.dropdowncontrol {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}
