.mapping-item-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 225px;
  cursor: pointer;
}

.mapping-item {
  width: 100%;
  margin-bottom: 16px;
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
}

.batchcarddiv {
  width: 100%;
  margin-bottom: 16px;
  padding: 32px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

.mapping-item-tag {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding-right: 12px;
  padding-left: 12px;
  float: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #65a943;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
}

.mapping-item-tag.drafttag {
  background-color: #c7c6c6;
}

.mapping-item-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* overflow: visible; */
  height: 24px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.mapping-icon {
  width: 75px;
  height: 75px;
  border-radius: 6px;
}

.mapping-item-date {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.mapping-item-api-category {
  right: 0px;
}

.mapping-item-name {
  left: -35px;
}

.mapping-item-detail-row {
  padding-top: 20px;
  margin-left: -40px;
}

.mapping-item-event-name {
  font-weight: bold;
}

.mapping-item-app-name {
  font-weight: bold;
}

.batchinfodiv {
  margin-top: 32px;
}

.batchinfodiv.mapping {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.batchinfoicon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  float: left;
}

.mappingappdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.listsubtext {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  line-height: 18px;
}

.listsubtext.headersubtext {
  margin-bottom: 8px;
}
