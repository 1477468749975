.plusicon {
  width: 24px;
  height: 24px;
  min-width: 1px;
  margin-right: 8px;
  float: left;
  opacity: 0.54;
}

.plusbutton {
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
}

.plusconditiontext {
  margin-top: 2px;
  float: left;
  color: rgba(0, 0, 0, 0.54);
}

.addconditionlinkdiv {
  padding: 8px 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  text-decoration: none;
}

.addconditionlinkdiv:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.addconditionlinkdiv.inactivelinkdiv {
  opacity: 0.38;
}

.conditiondiv {
  width: 100%;
  height: 50px;
}

.conditiondiv2 {
  width: 100%;
  margin-bottom: 8px;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.conditiondiv2:hover {
  border-color: #14b1e7;
}

.typeSection .typeIcons {
  display: none;
}

.typeSection:hover .typeIcons {
  display: block;
}

.fieldSection .fieldIcons {
  display: none;
}

.fieldSection:hover .fieldIcons {
  display: block;
}

.conditionSection .conditionIcons {
  display: none;
}

.conditionSection:hover .conditionIcons {
  display: block;
}

.conditiondiv2.conditionevent:hover {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
}

.conditiondiv2.hidden {
  display: none;
}

.conditiontitle {
  color: rgba(0, 0, 0, 0.54);
}

.conditiontitle.bottomtitle {
  margin-bottom: 16px;
}

.conditiontitle.hidden {
  display: none;
}

.listavatardiv {
  width: 66px;
  height: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
  float: left;
}

.listavatardiv.threelinesavatar {
  height: 96px;
  padding-top: 23px;
  padding-bottom: 23px;
}

.listavatarimage {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
  background-color: #fff;
}

.listlinesdiv {
  height: 80px;
  margin-left: 66px;
  padding-top: 19px;
  float: none;
  border-bottom: 1px none rgba(0, 0, 0, 0.1);
}

.listlinesdiv.listitemnoavatar {
  overflow: visible;
  width: 75%;
  margin-left: 0px;
  float: left;
}

.listlinesdiv.listitemnoavatar.listitemthreelines {
  height: 96px;
}

.listlinesdiv.listitemnoavatar.exhibitorlineitem {
  overflow: hidden;
  width: 600px;
}

.listlinesdiv.threelines {
  height: 96px;
}

.h3 {
  font-family: "Open Sans", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  line-height: 30px;
}

.h3.logintitle {
  margin-top: 0px;
  margin-bottom: 32px;
}

.listtitle {
  overflow: hidden;
  height: 24px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.listtitle.listtitlereportspage {
  margin-bottom: 10px;
}

.text-block-2 {
  color: rgba(0, 0, 0, 0.54);
  font-size: 21px;
  line-height: 21px;
}

.conjunctiondiv {
  margin-bottom: 9px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mappingiconbtn {
  width: 24px;
  margin-left: 12px;
  padding: 3px;
  border-radius: 4px;
  border: none;
  background: none;
  /* opacity: 0.54; */
}

.mappingicon.fieldmappingIcon {
  margin-top: -12px;
}

.mappingicon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.mappinginteriordiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
